.input-field_info {
  border: none;
  background-color: #131a34;
  font-family: "Josefin Sans";
  outline: none;
  font-size: 22px;
  padding: 15px;
  color: azure;
  border-radius: 5px;
}
.form-row > input {
  width: 50%;
  height: 45px;
  padding-left: 5%;
  padding-right: 10%;
  font-family: "PingFang SC", "Roboto", sans-serif;
  font-size: 18px;
  line-height: 22.5px;
  border: none;
  text-align: center;
}

.form-row > input:focus {
  outline: none;
}
