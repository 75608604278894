* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Encode Sans Expanded", sans-serif;
}
::-webkit-scrollbar {
  width: 5px; /* Decrease the width value to reduce scrollbar width */
}
/* Scroll bar track */
::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}

/* Scroll bar thumb */
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

/* Scroll bar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Scroll bar thumb when dragged */
::-webkit-scrollbar-thumb:active {
  background-color: #000;
}

@font-face {
  font-family: "Josefin Sans";
  src: url("font/JosefinSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
